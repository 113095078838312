import React from "react"
import { graphql } from "gatsby"
import { Carousel } from "react-bootstrap"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FooterSpotLight from "../components/footer-spot-light"
import MainBody from "../components/main-body"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <div className="container slide-show">
      <Carousel className="slide-show">
      {/* <Carousel.Item>
          <video class="video-fluid" autoplay loop muted>
            <source src="https://mdbootstrap.com/img/video/Lines.mp4" type="video/mp4" />
          </video>
          <Carousel.Caption>
            <h2 className="d-none d-md-block">A perfect school for Holistic growth</h2>
            <p>Education should lead to Enlightment, Harmony and Inner Peace.</p>
          </Carousel.Caption>
        </Carousel.Item> */}
      <Carousel.Item>
          <Img fluid={data.slide10ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">A perfect school for Holistic growth</h2>
            <p>Education should lead to Enlightment, Harmony and Inner Peace.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.slide09ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">A perfect school for Holistic growth</h2>
            <p>Education should lead to Enlightment, Harmony and Inner Peace.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.slide01ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">A perfect school for Holistic growth</h2>
            <p>Education should lead to Enlightment, Harmony and Inner Peace.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.slide02ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">A perfect school for Holistic growth</h2>
            <p>Education should lead to Enlightment, Harmony and Inner Peace.</p>
          </Carousel.Caption>
        </Carousel.Item>        
        <Carousel.Item>
          <Img fluid={data.slide03ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Perfect blend of Values and Education</h2>
            <p>Fostering Critical Thinking Abilities and Communication Skills in a Child.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.slide04ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Perfect blend of Values and Education</h2>
            <p>Fostering Critical Thinking Abilities and Communication Skills in a Child.</p>
          </Carousel.Caption>
        </Carousel.Item>        
        <Carousel.Item>
          <Img fluid={data.slide05ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Be a Part of The Modern Education System</h2>
            <p>Schools are like Temples which help Enlighten the Mind and Behavior of Students.</p>
          </Carousel.Caption>
        </Carousel.Item> 
        <Carousel.Item>
          <Img fluid={data.slide06ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Be a Part of The Modern Education System</h2>
            <p>Schools are like Temples which help Enlighten the Mind and Behavior of Students.</p>
          </Carousel.Caption>
        </Carousel.Item>         
        <Carousel.Item>
          <Img fluid={data.slide07ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Shiksha, Pragya and Sanskar</h2>
            <p>"Shiksha, Pragya and Sanskar" were carefully thought of to portray the idea of transmitting everything from a teacher to learner.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Img fluid={data.slide08ql.childImageSharp.fluid} />
          <Carousel.Caption>
            <h2 className="d-none d-md-block">Shiksha, Pragya and Sanskar</h2>
            <p>"Shiksha, Pragya and Sanskar" were carefully thought of to portray the idea of transmitting everything from a teacher to learner.</p>
          </Carousel.Caption>
        </Carousel.Item>                    
      </Carousel>
      </div>
      <MainBody />
      <FooterSpotLight />
  </Layout>
)

export default IndexPage

export const query1 = graphql `
  query {
    slide01ql: file(relativePath: {eq: "slideshow/1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide02ql: file(relativePath: {eq: "slideshow/2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide03ql: file(relativePath: {eq: "slideshow/3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide04ql: file(relativePath: {eq: "slideshow/4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide05ql: file(relativePath: {eq: "slideshow/5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    slide06ql: file(relativePath: {eq: "slideshow/6.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    slide07ql: file(relativePath: {eq: "slideshow/7.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    slide08ql: file(relativePath: {eq: "slideshow/8.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    slide09ql: file(relativePath: {eq: "slideshow/9.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    } 
    slide10ql: file(relativePath: {eq: "slideshow/10.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1900){
          ...GatsbyImageSharpFluid
        }
      }
    }             
  } 
`