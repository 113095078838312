import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from "gatsby"
import { FaFacebookSquare, FaYoutubeSquare } from 'react-icons/fa';

const FooterSpotLight = () => {
    const data = useStaticQuery(graphql`
    query {
    
        chairmanql: file(relativePath: {eq: "people/manager.jpg"}) {
            childImageSharp {
                fixed(width: 60) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        infraql: file(relativePath: {eq: "infrastructure/image-1.jpg"}) {
            childImageSharp {
                fixed(width: 263, height: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        directorql: file(relativePath: {eq: "people/director.jpg"}) {
            childImageSharp {
                fixed(width: 60) {
                    ...GatsbyImageSharpFixed
                }
            }
        }

        principalql: file(relativePath: {eq: "people/principal.jpg"}) {
            childImageSharp {
                fixed(width: 60) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
}
`)
    return <footer-spot-light>
        <div className="container footer-spot-light">
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-4 our-mentors border-bottom border-sm-bottom border-md-right border-lg-right border-xl-right">
                    <h3>Our Mentors</h3>
                    <div className="row">
                        <div className="col-3">
                            <Link to="/chairmans-message"><Img fixed={data.chairmanql.childImageSharp.fixed} /></Link>
                        </div>
                        <div className="col-9">
                            <Link to="/chairmans-message">
                                <p>Dr. B. P. Agrawal</p>
                                <strong><small>Chairman</small></strong><br/> <small></small>
                            </Link>
                        </div>                    
                    </div>
                    <div className="row">
                        <div className="col-3">
                        <Link to="/principals-message"><Img fixed={data.directorql.childImageSharp.fixed} /></Link>
                        </div>
                        <div className="col-9">
                            <Link to="/principals-message">
                                <p>Dr. Vishal Agrawal</p>
                                <strong><small>Principal</small></strong><br/> <small></small>
                            </Link>
                        </div>                    
                    </div>
                    {/* <div className="row">
                        <div className="col-3">
                        <Link to="/principal-message"><Img fixed={data.principalql.childImageSharp.fixed} /></Link>
                        </div>
                        <div className="col-9">
                            <Link to="/principals-message">
                                <p>Mrs. Sarika Saxena</p>
                                <strong><small>Principal</small></strong><br/> <small></small>
                            </Link>
                        </div>                    
                    </div>                                              */}
                </div>
                <div className="col-xl-3 d-none d-xl-block about-us border-xs-bottom border-sm-bottom border-md-right border-lg-right border-xl-right">
                    <h3><span>About us</span></h3>
                    <Img fixed={data.infraql.childImageSharp.fixed} />
                    <p>The main aim of the school is to give <strong>&quot;</strong>The total development in your ward<strong>&quot;</strong>.</p>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-4 border-xs-bottom border-sm-bottom border-md-right border-lg-right border-xl-right follow-us">
                    <h3>Follow us</h3>
                    <p>Didn't we got you connected? If not, blame us on not showing you earlier the following magic button.</p>
                    <p><FaFacebookSquare/>&nbsp;<a href="https://www.facebook.com/dbpasn/" target="_blank" rel="noreferrer" title="Facebook">Facebook</a></p>
                    <p><FaYoutubeSquare/>&nbsp;<a href="https://www.youtube.com/channel/UCFg4zvV05MMbiND8dGrTBgQ?view_as=subscriber" target="_blank" rel="noreferrer" title="Youtube">Youtube</a></p>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-4">
                <h3>Locate Us</h3>
                    <address>Dr B. P. Agrawal Shiksha Niketan<br /> Kalyankunj, Kanpur Road, <br />Near Yaqutganj Railway Station, <br /> Fathegarh, <br /> Farrukhabad, <br /> UP, India - 209749</address>
                    <address><abbr title="Phone">Phone:</abbr> 9565467882<br /><abbr title="Phone">Phone:</abbr> 9129298033<br /> <abbr title="Phone">Mail to:</abbr><a href="mailto:"> admin@dbpasn.com</a></address>
                </div>
            </div>
        </div>
    </footer-spot-light>
}
export default FooterSpotLight