import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const MainBody = () => {
    const data = useStaticQuery(graphql`
    query {
    
        flexql: file(relativePath: {eq: "main-body/flex-1.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ad1ql: file(relativePath: {eq: "advertisements/admissions-open-1.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ad2ql: file(relativePath: {eq: "advertisements/admissions-open-2.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ad3ql: file(relativePath: {eq: "advertisements/admissions-open-3.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ad4ql: file(relativePath: {eq: "advertisements/admissions-open-4.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        
        ad5ql: file(relativePath: {eq: "advertisements/admissions-open-5.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }        

        markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/main-body/i"}}) {
            edges {
              node {
                frontmatter {
                  heading
                }
                html
              }
            }
          }        
}
`)
    return <main-body>
        <div className="container main-body">
            <div className="row">
                <div className="col-md-2 our-stars d-none d-md-block">
                    <Img fluid={data.ad1ql.childImageSharp.fluid} />                    
                </div>
                <div className="col-md-2 our-stars d-md-none">
                    <Img fluid={data.ad4ql.childImageSharp.fluid} />                    
                </div>                
                <div className="col-md-8 our-philosphy">
                    <strong>{data.markupql.edges[0].node.frontmatter.heading}</strong>
                    <Img fluid={data.flexql.childImageSharp.fluid} />
                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{__html: data.markupql.edges[0].node.html}}
                    >
                    </div>
                </div>
                <div className="col-md-2 our-stars d-none d-md-block">
                    <Img fluid={data.ad3ql.childImageSharp.fluid} />                    
                </div>                
                <div className="col-md-2 our-stars d-md-none">
                    <Img fluid={data.ad5ql.childImageSharp.fluid} />                      
                </div>
            </div>
        </div>
    </main-body>
}
export default MainBody